<template>
  <div class="default_container">
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table1">
          <tr>
            <td>USER BRANCH</td>
            <td>SUMMARY TRANSMIT SLIP</td>
            <td>
              <img src="../../assets/temp_logo1_new.png" />
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table2">
          <tr>
            <td>
              INTERNAL
              <br />
              <span>BATCH 3</span>
            </td>
            <td>
              BDO - DRAKE BUSINESS
              SERVICES ASIA
            </td>
            <td>
              <div class="dispatch_date">
                Dispatch date:
                <span>22-03-2019</span>
              </div>
              <img src="../../assets/temp_barcode1.png" />
              <div class="bar_text">IDBSA101519</div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table3">
          <tr>
            <td>REF</td>
            <td>ADDRESSEE</td>
            <td>ITEM</td>
            <td>ITEM DESCRIPTION</td>
            <td>SENDER</td>
          </tr>
          <tr>
            <td>
              <div class="bar_text">IDBSA101334</div>
              <img src="../../assets/temp_barcode2.png" />
            </td>
            <td>
              S'JOSEPH PAINE
              DRAKE BUSINESS SERVICES ASIA
            </td>
            <td>1</td>
            <td>BROWN ENVELOPE</td>
            <td>SKYNYRD IBALIO</td>
          </tr>
          <tr>
            <td>
              <div class="bar_text">IDBSA101335</div>
              <img src="../../assets/temp_barcode2.png" />
            </td>
            <td>
              M'NINA LOPEZ
              DRAKE BUSINESS SERVICES ASIA
            </td>
            <td>1</td>
            <td>BROWN ENVELOPE</td>
            <td>SKYNYRD IBALIO</td>
          </tr>
        </table>
      </div>
    </section>
    <h3>2 Total Pcs</h3>
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table4">
          <tr>
            <td>
              <div class="small_text">repared by(MACO Representative)</div>
              <div class="big_text">Hub User</div>
              <br />Signature over Printed Name
            </td>
            <td>
              <div class="big_text">20/06/2019</div>Date
            </td>
            <td>
              <div class="small_text">Checked By: (BRANCH Personal)</div>
              <hr />
              <div class="small_text">Signature over Printed Name</div>
            </td>
            <td>
              <div class="big_text">20/06/2019</div>Date
            </td>
          </tr>
          <tr>
            <td>
              <div class="small_text">Prepared by(BRANCH Representative)</div>

              <br />
              <hr />Signature over Printed Name
            </td>
            <td>
              <div class="big_text">20/06/2019</div>
              <hr />Date
            </td>
            <td></td>
            <td>
              <div class="big_text">05:46:57 PM</div>Time
            </td>
          </tr>
          <tr>
            <td colspan="4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo,
              fugit ipsam.
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section class="centered">
      <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
      <a class="link_bt bt_save">Print</a>
    </section>
  </div>
</template>
<script>
export default {
  name: "TransmittalSlip",
  methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    }
  }
};
</script>
<style lang="scss">
.default_container {
  padding: 20px;
}
.default_container > section {
  padding: 0;
}
h2 {
  font-size: 30px;
  padding: 10px 0 10px 0;
  display: block;
  text-align: center;
  margin: auto;
  font-weight: 600;
}
.transmit_sub_container h3 {
  font-size: 24px;
  font-weight: 600;
}
.form_section_transmit {
  width: 100%;
  height: auto;
  background: #fff;
  margin: 20px 0;
}

.form_section_transmit table {
  width: 100%;
}
.transmit_table1 {
  background: #fff;
  tr {
    td {
      width: 30%;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      &:first-child {
        text-align: left;
      }
      &:last-child img {
        width: 100%;
        height: auto;
        max-width: 240px;
        display: block;
        margin: auto;
      }
      &:nth-child(2) {
        font-size: 18px;
        width: 40%;
      }
    }
  }
}
.transmit_table2 {
  background: #fff;
  tr {
    td {
      width: 30%;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      &:first-child {
        text-align: left;
        font-size: 18px;
        line-height: 22px;
        span {
          font-size: 16px;
        }
      }
      &:last-child img {
        width: 100%;
        height: auto;
        max-width: 320px;
        display: block;
        margin: auto;
      }
      &:nth-child(2) {
        font-size: 18px;
        width: 40%;
        font-weight: 400;
      }
      .dispatch_date {
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 10px;
        span {
          font-weight: 600;
        }
      }
      .bar_text {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
.transmit_table3 {
  background: #fff;
  border-collapse: collapse;
  border: 2px solid #999;

  tr {
    td {
      min-width: 100px;
      padding: 10px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border: 2px solid #999;
      border-right: 0;
      border-bottom: 0;

      .bar_text {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
      img {
        display: block;
        width: 100%;
        max-width: 290px;
        height: auto;
      }
    }
    &:first-child td {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.transmit_table4 {
  background: #fff;
  border-collapse: collapse;
  border: 2px solid #333;

  tr {
    td {
      min-width: 100px;
      padding: 10px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border: 2px solid #333;
      border-right: 0;
      border-bottom: 0;
      hr {
        display: block;
        margin-left: 0;
        border: 0;
        height: 3px;
        background: #333;
        width: 100%;
      }
      .small_text {
        font-size: 16px;
        padding-bottom: 30px;
      }
      .big_text {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 10px;
      }
      &:first-child {
        text-align: left;
      }
      &:nth-child(3) {
        text-align: left;
      }
    }
    &:last-child td {
      font-weight: bold;
      text-align: center;
    }
  }
}
a.link_bt {
  display: inline-block;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #333;
  color: #ccc;
  margin: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
a.link_bt#06A5ED {
  background: #000;
  color: #fff;
}
a.link_bt.bt_save {
  background: #004a7c;
  color: #fff;
}
section.centered {
  display: flex;
  justify-content: center;
}
</style>